
import { SystemUser, UserTypes } from "@/store/modules/user/user.types";
import { Pagination } from "@/types/types";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const userX = namespace("User");

@Component({})
export default class UserSelect extends Vue {
  @Prop() public label?: string;
  @Prop() public filled?: boolean;
  @Prop() public outlined?: boolean;
  @Prop() public dense?: boolean;
  @Prop() public disabled?: boolean;
  @Prop() public hideDetails?: boolean;
  @Prop() public rules?: unknown[];
  @Prop() value?: string;

  public user = "";

  @userX.Action(UserTypes.LOAD_USERS)
  public getUsers!: (pagination: Pagination) => void;

  @userX.State(UserTypes.USERS)
  public users!: SystemUser[];

  @userX.State(UserTypes.LOADING_USERS_STATE)
  public loadingUsers!: boolean;

  @Emit("input")
  save(id: string): string {
    return id;
  }

  @Watch("value")
  observeValue(): void {
    if (this.user !== this.value && this.value) {
      this.user = this.value;
    }
  }

  mounted(): void {
    this.getUsers({ page: 1, limit: 10 });
  }
}
